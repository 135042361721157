<template>
  <div class="home" style="height: 100%">
    <el-container style="height:100%">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main style="padding: 0;">
        <div style="text-align: center;">
          <el-carousel height="13rem" indicator-position="inside" style=" width: 100%">
            <el-carousel-item
              style=" display: flex; align-items: center; color: #eeeeee; background-image: url('https://citi-1308543285.cos.ap-shanghai.myqcloud.com/index_background.png'); background-repeat: repeat">
              <div style="margin: 0 auto; text-align: left; width: 100%">
                <div style="margin-left: 3%">
                  <h1>Text Analyst评估管理平台</h1>
                  <h2>做价值与风险中的胜者</h2>
                  <h3>Select your key dictionary, empower your value investment</h3>
                </div>
              </div>
            </el-carousel-item>
            <!--          <el-carousel-item style="">-->

            <!--          </el-carousel-item>-->
          </el-carousel>
        </div>
        <div style="padding: 1rem"></div>
        <span style="font-size: 10rem">404</span>
        <br>
        <span style="font-size: 3rem"> 页面走丢了，不妨到别处看看吧！ </span>
        <br>
        <span style="font-size: 1.5rem"> 5秒后返回首页 </span>
        <br>
        <div style="padding: 1rem"></div>
        <a href="/" style="padding: 1rem">
          <el-button loading="loading" type="primary"><span style="font-size: 1rem; padding: 1.5rem"> 返回首页 </span>
          </el-button>
        </a>


      </el-main>
    </el-container>
    <FooterLayout></FooterLayout>
  </div>
</template>

<script>
// @ is an alias to /src

import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayout from '../../components/FooterLayout'

export default {
  name: 'textualESG',
  components: {
    FooterLayout,
    HeaderLayout
  }
}

setTimeout('window.location.href = \'/\'', 5000)
</script>

<style>
.el-header {
  background-color: #34495E;
  color: #333;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}

h1 {
  font-size: 2em;
  font-weight: normal;
}

h2 {
  font-size: 1.75rem;
  font-weight: normal;
}

h3 {
  font-size: 1.4rem;
  font-weight: normal;
}

h4 {
  font-size: 1.1rem;
  font-weight: bold;
}
</style>
